import React from "react"

import AccountWrapper from "../../components/account/account-wrapper"
import ShippingAddress from "../../components/account/address/shipping-address"

const ShippingDetails = ({ location }) => {
  return (
    <AccountWrapper location={location} accountStep="shipping-details">
      <ShippingAddress></ShippingAddress>
    </AccountWrapper>
  )
}

export default ShippingDetails
